import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import LinkIcon from './LinkIcon.js';
import styles from './index.module.css';

export default function Hero() {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { contentKey: { eq: "homePage" } }) {
        frontmatter {
          avatarImage {
            childImageSharp {
              fixed(width: 130, height: 130, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          postsHeading
          description
          tagline
          avatarLink
        }
      }
    }
  `);

  const { avatarImage, avatarLink, description, tagline } =
    data.markdownRemark.frontmatter;

  return (
    <div className={styles.hero}>
      <div className="container">
        <h1 className="page-title">{tagline}</h1>
        <hr />
        <div className={styles.avatar}>
          <Img
            fixed={avatarImage.childImageSharp.fixed}
            className={styles.avatar__image}
          />
          <a href={avatarLink} className={styles.avatar__link}>
            <LinkIcon />
          </a>
        </div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
}
