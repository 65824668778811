import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Index from '../components/Index';
import Heading from '../components/Index/Heading';
import Pagination from '../components/Index/Pagination';

export default function IndexTemplate({ data, pageContext }) {
  return (
    <Layout>
      <SEO nameFirst="true" isHome="true" />
      <Hero />

      <div className="container">
        <Heading context={pageContext} />
        <Index posts={data.allMarkdownRemark.edges} />
        <Pagination context={pageContext} />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { contentKey: { eq: "blog" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
