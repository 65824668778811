import React from 'react';
import { Link } from 'gatsby';

export default function Pagination({ context }) {
  const previousPage =
    context.currentPage === 2 ? '/' : `/page/${context.currentPage - 1}`;
  const nextPage = `/page/${context.currentPage + 1}`;
  return (
    <div>
      {context.currentPage > 1 && (
        <Link to={previousPage}>&larr; Previous Page</Link>
      )}
      {context.currentPage < context.pageCount && (
        <Link to={nextPage}>Next Page &rarr;</Link>
      )}
    </div>
  );
}
