import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './index.module.css';

export default function Heading({ context }) {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { contentKey: { eq: "homePage" } }) {
        frontmatter {
          postsHeading
        }
      }
    }
  `);

  const { postsHeading } = data.markdownRemark.frontmatter;
  return <h2 className={styles.postsHeading}>{postsHeading}</h2>;
}
