import React from 'react';
import { Link } from 'gatsby';
import styles from './index.module.css';

export default function Index({ posts }) {
  return (
    <>
      {posts.map((node) => (
        <article key={node.node.id} className={styles.article}>
          <h3 className={styles.title}>
            <Link className={styles.link} to={node.node.fields.slug}>
              {node.node.frontmatter.title}
            </Link>
          </h3>
        </article>
      ))}
    </>
  );
}
